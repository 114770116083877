.loading-spinner {
    border: 2px solid transparent;
    border-top: 2px solid var(--mainColor);
    border-radius: 50%;
    width: 1.28571429rem;
    height: 1.28571429rem;
    animation: spin 1s linear infinite;
}

.loading-spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}