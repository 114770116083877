* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Black.otf');
  font-weight: black;
  font-style: normal;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Semibold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Light.otf');
  font-weight: 300;
  font-style: normal;
}