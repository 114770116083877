.mat-btn {
  font-size: 14px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-shadow: none;
  border-style: none;
  width: fit-content;
  height: 40px;
  border-radius: 100px;
  box-shadow: none;
  padding: 0 15px;
  line-height: normal;
  display: flex;
  cursor: pointer;
}

.mat-btn .anticon {
  display: flex;
}

.mat-btn .ant-btn-loading-icon {
  display: flex;
}

.mat-btn.mat-btn-primary {
  background-color: var(--mainColor);
  color: var(--white);
}

.mat-btn.mat-btn-primary:hover {
  background-color: var(--mainColor);
}

.mat-btn.mat-btn-primary:disabled {
  background-color: var(--mainColorLighter);
  color: var(--white);
}

.mat-btn.mat-btn-primary .anticon-loading {
  color: var(--white);
}

.mat-btn.mat-btn-radius {
  background-color: var(--mainColor);
  border-radius: 8px;
  color: var(--white);
}

.mat-btn.mat-btn-radius:hover {
  background-color: var(--mainColor);
}

.mat-btn.mat-btn-radius:disabled {
  background-color: var(--mainColorLighter);
  color: var(--white);
}

.mat-btn.mat-btn-radius .anticon-loading {
  color: var(--white);
}

.mat-btn.mat-btn-white:disabled {
  opacity: 0.5;
}

.mat-btn.mat-btn-white:disabled:hover {
  background-color: var(--white);
  color: var(--mainColor);
  border: 1px solid var(--mainColor);
}

.mat-btn.mat-btn-cancel {
  background-color: var(--white);
  font-size: 12px;
  color: var(--secondaryTextColor);
}

.mat-btn.mat-btn-cancel:hover {
  background-color: var(--lightgrey);
}

.mat-btn.mat-btn-white {
  background-color: var(--white);
  font-size: 12px;
  color: var(--mainColor);
  border: 1px solid var(--mainColor);
}

.mat-btn.mat-btn-danger {
  background: var(--red);
  border-color: transparent;
  color: var(--white);
}

.mat-btn.mat-btn-white:hover {
  background-color: var(--white);
}

.mat-btn.mat-btn-gmail {
  background-color: #dc4a38;
  color: var(--white);
}

.mat-btn.mat-btn-gmail:disabled {
  opacity: 0.5;
}

.mat-btn.mat-btn-gmail:disabled:hover {
  background-color: #dc4a38;
  color: var(--white);
}

.mat-btn.mat-btn-red {
  background-color: rgba(241, 43, 44, 0.15);
  color: var(--red);
}

.mat-btn.mat-btn-red:disabled {
  opacity: 0.5;
}

.mat-btn.mat-btn-red:disabled:hover {
  background-color: rgba(241, 43, 44, 0.15);
  color: var(--red);
}

.mat-btn.mat-btn-microsoft {
  background-color: #0072c6;
  color: var(--white);
}

.mat-btn.mat-btn-microsoft:disabled {
  opacity: 0.5;
}

.mat-btn.mat-btn-microsoft:disabled:hover {
  background-color: #0072c6;
  color: var(--white);
}

.mat-btn.mat-btn-facebook {
  background-color: #4c69ba;
  color: var(--white);
  min-width: 130px;
}

.mat-btn+.mat-btn {
  margin-left: 20px;
}

.mat-btn.mat-btn-large {
  height: 54px;
  font-size: 16px;
}

.mat-btn.mat-btn-small {
  height: 30px;
}

[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.img-crop-modal .ant-btn {
  font-size: 14px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-shadow: none;
  border-style: none;
  width: fit-content;
  height: 40px;
  border-radius: 100px;
  box-shadow: none;
  padding: 0 15px;
  line-height: normal;
  display: flex;
  background-color: var(--white);
  font-size: 12px;
  color: var(--secondaryTextColor);
}

.img-crop-modal .ant-btn:hover {
  background-color: var(--lightgrey);
}

.img-crop-modal .ant-btn.ant-btn-primary {
  background-color: var(--mainColor);
  color: var(--white);
}

.img-crop-modal .ant-btn.ant-btn-primary:hover {
  background-color: var(--mainColor);
}