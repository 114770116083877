.row-div-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.row-div-container>* {
  margin-right: 20px;
}

.row-div-container>*:last-child {
  margin-right: 0 !important;
}

.row-div-container.row-flex-start {
  align-items: flex-start;
}

.row-div-container.col-flex-start {
  justify-content: flex-start;
}

.row-div-container.row-flex-end {
  align-items: flex-end;
}

.row-div-container.col-flex-end {
  justify-content: flex-end;
}

.row-div-container.space-between {
  justify-content: space-between;
}