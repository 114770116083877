.mat-textarea-wrapper .mat-textarea {
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  letter-spacing: 0.58px;
  border-radius: 4px;
  border: 1px solid var(--grey);
  overflow-y: auto;
  font-family: 'SFPro', sans-serif !important;
  line-height: 25px;
  padding: 10px 10px 10px 17px;
  resize: none;
  width: 100%;
  border: 1px solid var(--grey);
}

.mat-textarea-wrapper {
  position: relative;
}

.mat-textarea-wrapper .mat-textarea:disabled {
  background-color: var(--lighter);
  border-color: var(--lighter);
  color: var(--thridyTextColor);
}

.mat-textarea-wrapper .mat-textarea:focus {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColorLighter);
}

.mat-textarea-wrapper .ant-form-item-has-error .mat-textarea {
  border: 1px solid #ff4d4f;
  box-shadow: none;
}

.mat-textarea-wrapper .clear-icon {
  position: absolute;
  top: 5px;
  right: 7px;
  cursor: pointer;
}