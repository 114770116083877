.feedback-page-wrapper {
  align-items: center;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.feedback-body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback-body-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-width: 500px;
  color: var(--secondaryTextColor);
  padding-top: 10px;
  word-break: break-word;
}

.feedback-nps-numbers-wrapper {
  padding-top: 40px;
}

.nps-number-item-wrapper {
  justify-content: space-between;
}

.nps-number-item-wrapper > * {
  margin-right: 11px !important;
}

.nps-number-item-wrapper .number-item {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--mainColorLighter);
  border-radius: 14px;
  color: var(--mainColor);
  font-size: 24px;
  font-weight: 700;
  line-height: 29.66px;
  cursor: pointer;
  z-index: 1;
}

.nps-number-item-wrapper .number-item .anticon {
  display: flex;
}

.nps-number-item-wrapper .number-item .anticon svg {
  width: 32px;
  height: 32px;
}

.nps-number-item-wrapper .number-item.active {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColorLighter);
}

.feeback-lucky-wrapper {
  padding-top: 25px;
}

.feeback-lucky-wrapper span {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--secondaryTextColor);
}

.feedback-icon-wrapper {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  max-width: 800px;
  line-height: 54px;
}

.nps-score-abs {
  position: absolute;
}

.nps-score-abs-icon-left-top {
  top: 0;
  left: 100px;
}

.nps-score-abs-icon-left-bottom {
  bottom: 0;
  left: 0;
}

.nps-score-abs-icon-right-top {
  top: -10px;
  right: 0;
}

.nps-score-abs-icon-left-center {
  top: 30%;
  left: 100px;
}

.nps-score-abs-icon-right-center {
  right: 30%;
  bottom: 100px;
}

.positive-feedback-page {
  height: auto;
  padding: 20px 0;
  position: static;
  overflow: auto;
}

.positive-feedback-page .nps-feedback-title.strong {
  font-size: 32px;
  max-width: 850px;
  line-height: 50px;
}

.nps-feedback-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.nps-feedback-title.strong {
  font-weight: 700;
  padding-bottom: 15px;
  font-size: 36px;
  max-width: 800px;
}

.link-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  z-index: 1;
}

.link-button-wrapper .mat-btn {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transform: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-button-wrapper .list-url-button span {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.link-button-wrapper .list-url-button svg {
  margin-right: 10px;
}

.link-button-wrapper .link-button {
  color: var(--secondaryTextColor);
  background-color: var(--lightgrey);
}

.link-button-wrapper .skip-button {
  color: var(--white);
  background-color: transparent;
}

.link-button-wrapper .mat-btn + .mat-btn {
  margin-left: 0;
  margin-top: 15px;
}

.link-button-wrapper .link-button:hover {
  color: var(--white);
  background-color: var(--green);
}

.feedback-button-wrapper {
  justify-content: center;
  padding-top: 71px;
}

.feedback-button-wrapper .mat-btn {
  width: 174px;
  z-index: 1;
}

.feedback-page-wrapper .mat-textarea-wrapper {
  max-width: 500px;
  width: 100%;
  margin-top: 30px;
  background-color: transparent;
}

@media screen and (max-width: 991px) {
  .nps-number-item-wrapper .number-item {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 767px) {
  .nps-number-item-wrapper .number-item {
    width: 40px;
    height: 40px;
    font-size: 20px;
    border-radius: 9px;
  }

  .nps-number-item-wrapper .number-item .anticon svg {
    width: 25px;
    height: 25px;
  }

  .feedback-nps-numbers-wrapper {
    padding-top: 20px;
  }

  .nps-feedback-title.strong {
    font-size: 24px;
  }

  .feeback-lucky-wrapper span {
    font-size: 16px;
  }

  .feeback-lucky-wrapper {
    padding-top: 15px;
  }

  .nps-score-abs-icon-left-top {
    width: 200px;
    left: 0;
  }

  .nps-score-abs-icon-left-top svg {
    width: 100%;
  }
}

@media screen and (max-width: 530px) {
  .feedback-page-wrapper {
    padding: 15px;
    height: auto;
    position: static;
  }

  .feedback-body-title {
    font-size: 15px;
    line-height: 22px;
  }

  .feedback-icon-wrapper {
    font-size: 28px;
    line-height: 40px;
  }

  .nps-number-item-wrapper > * {
    margin-right: 5px;
  }

  .nps-number-item-wrapper .number-item {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  .nps-number-item-wrapper .number-item .anticon svg {
    width: 18px;
    height: 18px;
  }

  .feeback-lucky-wrapper span {
    font-size: 14px;
  }

  .feedback-button-wrapper {
    padding-top: 40px;
  }

  .link-button-wrapper {
    width: 100%;
  }

  .link-button-wrapper .list-url-button {
    height: 40px !important;
    width: 100% !important;
  }

  .link-button-wrapper .list-url-button svg {
    width: 24px;
    height: 24px;
  }

  .nps-score-abs-icon-right-top .anticon {
    height: 100vh;
  }

  .nps-score-abs-icon-right-top svg {
    height: 100% !important;
  }

  .nps-score-abs-icon-left-bottom,
  .nps-score-abs-icon-left-bottom svg {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .nps-number-item-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .nps-number-item-wrapper > * {
    margin: 4px !important;
  }

  .row-div-container > *:last-child {
    margin-right: 4px !important;
  }
}

@media screen and (max-width: 380px) {
  .nps-number-item-wrapper .number-item {
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
  }
}
